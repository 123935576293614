import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import classes from "../components/single.module.css"
import Title from "../components/title/subtitle"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const BlogPostTemplate = ({ data }) => {
  useEffect(() => {
    gsap.fromTo(
      ".post-content-header",
      { y: 20, opacity: 0 },
      { y: 0, opacity: 1 }
    )
    gsap.fromTo(
      ".desc",
      { y: 20, opacity: 0 },
      { y: 0, opacity: 1, delay: 0.3 }
    )
    gsap.fromTo(
      ".kg-card",
      { y: 20, opacity: 0 },
      { y: 0, opacity: 1, delay: 0.6 }
    )
    gsap.fromTo(
      ".post-content-width",
      { y: 20, opacity: 0 },
      { y: 0, opacity: 1, delay: 0.6 }
    )

    /*     var tl = gsap.timeline()
    tl.fromTo(
      ".post-content-header",
      { y: 20, opacity: 0 },
      {
        opacity: 1,
        y: 0,
      }
    )
    tl.fromTo(
      ".desc",
      { y: 20, opacity: 0 },
      {
        opacity: 1,
        y: 0,
      }
    )
    tl.fromTo(
      ".kg-card",
      { y: 20, opacity: 0 },
      {
        opacity: 1,
        y: 0,
      }
    )
    tl.fromTo(
      ".post-content-width",
      { y: 20, opacity: 0 },
      {
        opacity: 1,
        y: 0,
      }
    ) */
  }, [])

  const post = data.contentfulBlogPost
  return (
    <Layout>
      <SEO
        title={post.title}
        description={`しょうのまきの${post.title}のブログはこちらから。東京でイラストデザイン制作やロゴデザイン、チラシ制作、フライヤー制作、パンフレットの制作、カード類の制作などの広告印刷物の制作などおこなっております。`}
        keywords={[
          `イラスト制作`,
          `美容室の広告印刷物の制作`,
          `ロゴデザイン`,
          `チラシ制作`,
        ]}
      />

      <header className="post-content-header">
        {post.category ? (
          <p className={`${classes.category}`}>
            <Link to={`/blog/category/${post.category.slug}`}>
              {post.category.name}
            </Link>
          </p>
        ) : null}
        <Title title={post.title} className="post-content-title" />
      </header>

      <article className={classes.postWrap}>
        <div
          className={`desc post-content-body ${classes.desc}`}
          dangerouslySetInnerHTML={{
            __html: post.description.childMarkdownRemark.html,
          }}
        />
        <div className="post-content-date">{post.publishDate}</div>

        <figure className="kg-card kg-image-card kg-width-full">
          {post.heroImage ? (
            <Img
              className="kg-image"
              fluid={post.heroImage ? post.heroImage.fluid : null}
              alt={post.title}
              backgroundColor="#f5adc2"
            />
          ) : (
            <Img
              className="kg-image"
              fluid={data.file.childImageSharp.fluid}
              alt={post.title}
              placeholderStyle={{ backgroundColor: "rgb(248, 248, 248)" }}
            />
          )}
        </figure>

        <div
          className="post-content-body  post-content-width"
          dangerouslySetInnerHTML={{
            __html: post.body.childMarkdownRemark.html,
          }}
        />
      </article>
    </Layout>
  )
}
export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    file(relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }) {
      childImageSharp {
        fluid(
          maxWidth: 1500
          maxHeight: 1000
          cropFocus: CENTER
          quality: 85
          traceSVG: {
            color: "#fce4eb"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    contentfulBlogPost(slug: { eq: $slug }) {
      title
      category {
        name
        slug
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      publishDate(formatString: "YYYY年MM月DD日")
      heroImage {
        fluid(
          resizingBehavior: PAD
          maxWidth: 1400
          maxHeight: 910
          quality: 85
        ) {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
